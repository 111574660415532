/* You can add global styles to this file, and also import other style files */
@import url('https://mitel.io/assets/css/MuseoSans-100.css');
@import url('https://mitel.io/assets/css/MuseoSans-300.css');
@import url('https://mitel.io/assets/css/MuseoSans-500.css');
@import url('https://mitel.io/assets/css/MuseoSans-700.css');
@import url('https://mitel.io/assets/css/MuseoSans-900.css');
@import "~bootstrap/dist/css/bootstrap.css";
@import "~font-awesome/css/font-awesome.css";

html {
    height: 100%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
    background: #FCFDFD;
    position: relative;
    margin: 0;
    margin-top: 25px;
    padding-bottom: 6rem;
    min-height: 100%;
}

.modal-dialog {
    margin-top: 128px;
	max-width: 675px;
}

.modal-content {
	width: 675px;
}

.modal-backdrop {
    background: #15325F !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand {
  font-family: "MuseoSans-500", Helvetica, sans-serif !important;
}

input[type=text], input[type=password], input[type=email], input[type=tel]{
	color: #404141;
	line-height: 28px;
	background-color: #fff;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border: 1px solid #BFBFC0;
    font: 12px MuseoSans-300, arial;
    padding: 0px 14px;
    height: 30px;
    margin-bottom: 17px;
}

input[type=text]:disabled, input[type=password]:disabled, input[type=email]:disabled, input[type=tel]:disabled{
    background: #FFFFFF;
    border: 1px solid #E6E7E8;
}

input.ng-invalid.ng-touched, input.ng-invalid.ng-touched {
	border: 1px solid red;
}

.spacer {
    height: 18px;
}

.center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

button {
    cursor: pointer;
}

button.btn-primary {
    border: none;
    border-radius: 0px !important;
    font-size: 13px;
    font-family: "MuseoSans-500";
    box-shadow: none !important;
    cursor: pointer;
    background-color: #00A1E0;
    color: #fff;
    height: 28px;
    text-align: center;
    margin: auto;
    line-height: 0.6;
}

button.btn-primary:hover, button.btn-primary:focus:hover {
    border: none;
    box-shadow: none !important;
    background-color: #00A1E0;
    opacity: 0.7;
}

button.btn-primary:disabled {
    background-color: #00A1E0;
    opacity: 0.3;
}

button.btn-primary:focus {
    box-shadow: none !important;
    border: 1px solid #0081B3;
    background-color: #00A1E0;
}

button.btn-primary:active, button.btn-primary:hover:active {
    background-color: #0081B3;
}

button.btn-secondary {
    border: none;
    border-radius: 0px !important;
    font-size: 13px;
    font-family: "MuseoSans-500";
    box-shadow: none !important;
    cursor: pointer;
    background-color: #F2F2F2;
    color: #15325F;
    height: 28px;
    text-align: center;
    margin: auto;
    line-height: 0.6;
}

button.btn-secondary:hover, button.btn-secondary:focus:hover {
    border: none;
    box-shadow: none !important;
    background-color: #F2F2F2;
    opacity: 0.7;
    color: #15325F;
}

button.btn-secondary:disabled, button.btn-secondary:disabled {
    background-color: #F2F2F2;
    opacity: 0.3;
}

button.btn-secondary:focus {
  box-shadow: none !important;
  border: 1px solid #CCCDCE;
  background-color: #F2F2F2;
  color: #15325F;
}

button.btn-secondary:active, button.btn-secondary:hover:active {
    background-color: #6D6D70;
    color: #FFFFFF;
}

button.btn-success {
    border: none;
    border-radius: 0px !important;
    font-size: 13px;
    font-family: "MuseoSans-500";
    box-shadow: none !important;
    cursor: pointer;
    background-color: #3FAC49;
    color: #FFF;
    height: 28px;
    text-align: center;
    margin: auto;
    line-height: 0.6;
}

button.btn-success:hover, button.btn-success:focus:hover {
    border: none;
    box-shadow: none !important;
    background-color: #3FAC49;
    opacity: 0.7;
}

button.btn-success:disabled, button.btn-success.disabled {
    background-color: #3FAC49;
    opacity: 0.3;
}

button.btn-success:focus {
    box-shadow: none !important;
    border: 1px solid #399041;
    background-color: #3FAC49;
}

button.btn-success:active, button.btn-success:hover:active {
    background-color: #399041;
}

button.btn-danger {
    border: none;
    border-radius: 0px !important;
    font-size: 13px;
    font-family: "MuseoSans-500";
    box-shadow: none !important;
    cursor: pointer;
    background-color: #ED1C24;
    color: #FFF;
    height: 28px;
    text-align: center;
    margin: auto;
    line-height: 0.6;
}

button.btn-danger:hover, button.btn-danger:focus:hover {
    border: none;
    box-shadow: none !important;
    background-color: #ED1C24;
    opacity: 0.7;
}

button.btn-danger:disabled, button.btn-danger.disabled {
    background-color: #ED1C24;
    opacity: 0.3;
}

button.btn-danger:focus {
    box-shadow: none !important;
    border: 1px solid #C51D23;
    background-color: #ED1C24;
}

button.btn-danger:active, button.btn-danger:hover:active {
    background-color: #C51D23;
}

button.btn-link {
    color: #00a1e0;
}

.btn-link:focus,
.btn-link:active:focus{
  outline: none;
  box-shadow: none;
}

.btn-link:focus{
  text-decoration: none;
}

.btn-link:hover{
    text-decoration: underline;
}

.text-danger {
    font-family: MuseoSans-500;
    font-size: 11px;
    color: #ED1C24 !important;
}

span.info > img {
    vertical-align: top;
    height: 16px;
    width: 27px;
    margin-top: 7px;
    padding: 0px;
    cursor: pointer;
}
